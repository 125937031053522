'use strict';

export const getDefaultLineChartProperties = ({ useNewPointRadiusStyle = false } = {}) => {
    return {
        pointBackgroundColor: '#068BFF',
        fill: false,
        borderColor: '#068BFF',
        pointBorderColor: '#068BFF',
        pointRadius: useNewPointRadiusStyle ? 4 : 2,
        borderWidth: 1,
        tension: 0.1
    };
};
