<template>
    <header class="navigation-container">
        <StaffSchoolBanner v-if="this.showStaffSchoolBanner" />
        <button class="skip-to-content-link" @click="focusOnMainContent">
            Skip to content
        </button>
        <nav class="navigation">
            <div
                class="navigation__item-container navigation__item-container--left"
                :class="{ 'show-alerts-badge': showAlertsBadge }"
            >
                <Menubar :items="navItems" type="MAIN_NAVIGATION" ariaLabel="Main navigation">
                    <template #start>
                        <router-link
                            class="navigation__item navigation__item--unselected"
                            aria-label="Home"
                            :to="{ name: this.showStaffNav ? 'StaffHome' : 'Home' }"
                            exact
                        >
                            <svg height="45" width="45" viewBox="0 0 1200.000000 1200.000000">
                                <use xlink:href="#icon-logo" />
                            </svg>
                        </router-link>
                    </template>
                    <template #end>
                        <span
                            v-if="showAlertsBadge"
                            class="navigation__item navigation__item--badge-count navigation__item--badge"
                            >{{ pendingAlertCount }}</span
                        >
                    </template>
                </Menubar>
            </div>
            <div class="navigation__item-container navigation__item-container--right">
                <!-- this search feature is not accessible so we're hiding it from schools that require accessibility -->
                <div v-if="!isExperimentalAccessibilityEnabled" class="search-box">
                    <SearchInput
                        v-if="this.showAdminNav"
                        :modelValue="apiParams.search"
                        class="navigation__search-input"
                        @update:modelValue="onSearch($event)"
                        :isSmall="true"
                        ariaLabel="Search students"
                    />
                    <SearchStudents
                        v-if="apiParams.search"
                        :students="students"
                        :maxStudents="5"
                        @student="redirectToStudentDetail($event)"
                        @seeAll="onSeeAllStudents()"
                    />
                </div>

                <div v-if="this.showEnterSchoolButton">
                    <StaffEnterSchoolButton />
                </div>

                <router-link
                    v-if="this.showAdminNav && isExperimentalAccessibilityEnabled"
                    :to="{ name: 'Sitemap' }"
                    class="sitemap-link"
                    @click="$emit('untoggled-school')"
                    :aria-label="
                        !isExperimentalAccessibilityEnabled ? 'Accessibility Sitemap' : null
                    "
                >
                    <div class="icon-sitemap">
                        <img src="@/assets/icons/accessibility.svg" alt="Sitemap" />
                    </div>
                </router-link>

                <router-link
                    v-if="this.showAdminNav"
                    :to="{ name: 'Resources' }"
                    class="help-link"
                    @click="$emit('untoggled-school')"
                    :aria-label="!isExperimentalAccessibilityEnabled ? 'Resources' : null"
                    v-tooltip="!isExperimentalAccessibilityEnabled ? 'Resources' : {}"
                >
                    <div
                        :class="
                            isExperimentalAccessibilityEnabled
                                ? 'icon-resources accessible-resources'
                                : 'icon-resources'
                        "
                    >
                        <img src="@/assets/icons/question-mark-alt.svg" alt="Question mark" />
                        <span v-if="this.isExperimentalAccessibilityEnabled">Resources</span>
                    </div>
                </router-link>

                <div class="vertical-line"></div>

                <div class="dropdown-container account-settings">
                    <MenuDropDown :actions="menuItems" arrowEnabled>
                        <button
                            v-if="userIsLoggedIn"
                            tabindex="0"
                            @click="() => {}"
                            class="user"
                            aria-label="Settings menu"
                        >
                            <div>
                                <div class="user__avatar">
                                    <template v-if="user.displayName">
                                        <span class="user__initials">{{
                                            firstSym(user.firstName)
                                        }}</span>
                                        <span class="user__initials">{{
                                            firstSym(user.lastName)
                                        }}</span>
                                    </template>
                                    <template v-else>
                                        <span>N/A</span>
                                    </template>
                                </div>
                            </div>
                        </button>
                    </MenuDropDown>
                </div>
            </div>
            <admin-settings-modal v-if="user.isAdmin"></admin-settings-modal>
        </nav>
    </header>
</template>

<script>
import { poll } from '@edsights/polling';
import { mapGetters, mapState } from 'vuex';
import AdminSettingsModal from '@/views/AdminSettings/AdminSettingsModal';
import Auth from '@/services/auth';
import MenuDropDown from '@/components-deprecated/MenuDropDown';
import Student from '@/services/students';
import SearchInput from '@/components-deprecated/inputs/SearchInput';
import SearchStudents from '@/components-deprecated/inputs/SearchStudents';
import NavigationDropDown from '@/components-deprecated/NavigationDropDown';
import { debounce } from '@/services/utils';
import StaffEnterSchoolButton from '@/components-deprecated/staff/StaffEnterSchoolButton';
import StaffSchoolBanner from '@/components-deprecated/staff/StaffSchoolBanner';
import Menubar from '@/components-deprecated/global/v2/Menubar';
import {
    isExperimentalAccessibilityEnabled,
    isSvsEnabled,
    isUIFeatureFlagEnabled
} from '@/lib/feature-flag';

export default {
    components: {
        StaffSchoolBanner,
        StaffEnterSchoolButton,
        NavigationDropDown,
        AdminSettingsModal,
        MenuDropDown,
        SearchInput,
        SearchStudents,
        Menubar
    },
    mounted() {
        if (this.showAdminNav) {
            this.pollPendingAlertCount();
        }
    },
    props: {
        showSchools: Boolean
    },
    data() {
        return {
            dropdownData: [
                {
                    name: 'All Settings',
                    onclick: this.handleSettings
                },
                {
                    name: 'Logout',
                    onclick: this.handleLogout
                }
            ],
            // special dropdown for schools
            schoolListDropDown: [],
            apiParams: {
                search: '',
                filters: {}
            },
            students: [],
            schools: [],
            pendingAlertCountStopPolling: null,
            pendingAlertPollingStarted: false,
            isRelocateKnowledgeAnalyticsEnabled: isUIFeatureFlagEnabled(
                'VUE_APP_FEATURE_FLAG_RELOCATE_KNOWLEDGE_ANALYTICS'
            )
        };
    },
    computed: {
        ...mapGetters(['userIsLoggedIn']),
        ...mapState(['user', 'staffSchoolViewEnabled']),
        ...mapState(['pendingAlertCount']),
        isStaff() {
            return this.user.isStaff;
        },
        isAdmin() {
            return this.user.isAdmin;
        },
        menuItems() {
            let items = [...this.dropdownData];
            if (this.showStaffNav) {
                // quick way to remove the All Settings option from the drop down menu
                items.shift();
            }
            return items;
        },
        showStaffNav() {
            return this.isStaff && !this.staffSchoolViewEnabled;
        },
        showAdminNav() {
            return (
                (this.isAdmin && !this.isStaff) ||
                (this.isStaff && this.isAdmin && this.staffSchoolViewEnabled)
            );
        },
        showStaffSchoolBanner() {
            return this.isStaff && this.isAdmin && this.staffSchoolViewEnabled;
        },
        showEnterSchoolButton() {
            return this.isStaff && this.isAdmin && !this.staffSchoolViewEnabled;
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        },
        isSvsEnabled() {
            if (this.$store.state.user) {
                return isSvsEnabled(this.$store.state.user.isStaff);
            }

            return false;
        },
        navItems() {
            let items = [];

            if (this.showStaffNav) {
                items = [
                    {
                        label: 'Chatbot',
                        routeName: 'ChatbotFlowList'
                    },
                    {
                        label: 'Texts',
                        routeName: 'ChatbotTextsPage'
                    },
                    {
                        label: 'Students',
                        routeName: 'StaffStudentsPage'
                    },
                    {
                        label: 'Uploads',
                        routeName: 'Uploadsv1Page'
                    },
                    {
                        label: 'Uploads v2',
                        routeName: 'UploadsPage'
                    },
                    {
                        label: 'SFTP',
                        routeName: 'UploadsSFTPPage'
                    },
                    {
                        label: 'Staff Tools',
                        routeName: 'StaffToolsList'
                    }
                ];
            }

            if (this.showAdminNav) {
                items = [
                    {
                        label: 'Activity',
                        items: [
                            {
                                label: 'Chatbot Messaging',
                                routeName: 'ChatbotMessagingPage'
                            },
                            {
                                label: 'Student Engagement',
                                routeName: 'EngagementAnalyticsPage'
                            },
                            ...(this.isRelocateKnowledgeAnalyticsEnabled
                                ? [
                                      {
                                          label: 'Knowledge Analytics',
                                          routeName: 'KnowledgeAnalyticsPage'
                                      }
                                  ]
                                : [])
                        ]
                    },
                    {
                        label: 'Interventions',
                        routeName: 'InterventionsPage'
                    },
                    {
                        label: 'Students',
                        routeName: 'AnalysisStudents'
                    },
                    {
                        label: 'Insights',
                        items: [
                            {
                                label: 'Retention Insights',
                                routeName: 'InsightsPage'
                            },
                            ...(this.isSvsEnabled
                                ? [
                                      {
                                          label: 'Student Voice Score',
                                          routeName: 'StudentVoiceScorePage'
                                      }
                                  ]
                                : []),
                            ...(!this.isRelocateKnowledgeAnalyticsEnabled
                                ? [
                                      {
                                          label: 'Knowledge Analytics',
                                          routeName: 'KnowledgeAnalyticsPage'
                                      }
                                  ]
                                : [])
                        ]
                    },
                    {
                        label: this.alertsPageLabel,
                        routeName: 'Alerts'
                    }
                ];
            }

            return this.formatNavItems(items, this.$route);
        },
        // For schools with accessibility enabled, we remove the alerts "badge" from the
        // nav and instead render the alert count in the action link label, as text:
        showAlertsBadge() {
            if (this.isExperimentalAccessibilityEnabled) {
                return false;
            }

            return (
                this.showAdminNav && this.pendingAlertPollingStarted && this.pendingAlertCount > 0
            );
        },
        // Text for the alerts page label, based on accessibility need:
        alertsPageLabel() {
            if (this.isExperimentalAccessibilityEnabled && this.pendingAlertPollingStarted) {
                return `Alerts (${this.pendingAlertCount})`;
            }

            return 'Alerts';
        }
    },
    async created() {
        if (this.staffSchoolViewEnabled) {
            this.apiParams.school = this.user.school;
        }
        this.onSearch = debounce(this.onSearch, 700);
    },

    methods: {
        navigate(routeName) {
            this.$router.push({ name: routeName });
        },
        firstSym(value) {
            if (!value) {
                return '';
            }

            if (value.length === 0) {
                return '';
            }

            return value[0].toUpperCase();
        },
        focusOnMainContent() {
            const mainContent = document.querySelector('#main');
            if (mainContent) {
                mainContent.focus();
            }
        },
        handleLogout() {
            Auth.logout();
            this.$router.push({ name: 'Login' });
        },
        handleSettings() {
            this.$router.push({ name: 'Settings' });
        },
        redirectToStudentDetail(student) {
            this.$router.push({ name: 'StudentDetail', params: { id: student.id } });
            // Clear local search parameters to hide Search Results box
            this.apiParams.search = '';
        },
        onSeeAllStudents() {
            // Navigate to Students page, and apply search parameters
            this.$router.push({
                name: 'AnalysisStudentsWithSearch',
                params: { searchQuery: this.apiParams.search }
            });
            // Clear local search parameters to hide Search Results box
            this.apiParams.search = '';
        },
        async onSearch(value) {
            this.apiParams.search = value.trim();
            await Student.api.list(this.apiParams).then(response => {
                this.students = response.results;
            });
        },
        pollPendingAlertCount() {
            const POLLING_INTERVAL = 1000 * 60 * 5;
            this.pendingAlertCountStopPolling = poll(async () => {
                if (!this.$store.getters.userIsLoggedIn) {
                    this.pendingAlertCountStopPolling();
                    return;
                }

                await this.$store.dispatch('loadPendingAlertCount');

                // since vuex state is cached in local storage, use this as a hook so the template
                // doesn't show an old value before polling has started
                this.pendingAlertPollingStarted = true;
            }, POLLING_INTERVAL);
        },
        getNavItemClass(navItem, currentRoute) {
            let routeNames = [];

            // Determine if this is a group of items or a single item, and collect the routeNames
            if (navItem.items) {
                navItem.items.forEach(item => routeNames.push(item.routeName));
            } else {
                routeNames.push(navItem.routeName);
            }

            return routeNames.includes(currentRoute.name) ? 'active-route' : '';
        },
        formatNavItems(navItems, currentRoute) {
            return navItems.map(item => {
                return {
                    label: item.label,
                    class: this.getNavItemClass(item, currentRoute),
                    ...(item.routeName ? { command: () => this.navigate(item.routeName) } : {}),
                    ...(item.items ? { items: this.formatNavItems(item.items, currentRoute) } : {})
                };
            });
        }
    },
    watch: {
        async showAdminNav(value) {
            if (value !== true && typeof this.pendingAlertCountStopPolling === 'function') {
                this.pendingAlertCountStopPolling();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.skip-to-content-link {
    left: 50%;
    position: absolute;
    transform: translateY(-100%);
}

.skip-to-content-link {
    background: $primary-brand-color;
    height: 30px;
    left: 50%;
    padding: 8px;
    position: absolute;
    transform: translateY(-100%);
    transition: transform 0.3s;
    color: #fff;
    display: flex;
    align-items: center;
}

.skip-to-content-link:focus {
    transform: translateY(0%);
}

// Make room for the alerts badge.
// This is done instead of passing the badge through a slot on the Menubar
// as that would likely make us have to maintain the keyboard and screen
// reader accessibility instead of leaning on the 3rd party component's
// accessibility.
.show-alerts-badge {
    :deep(.menu > .menuitem:last-child) {
        a {
            padding-right: 40px;
        }
    }

    :deep(.navigation__item--badge) {
        position: relative;
        left: -42px;
        pointer-events: none;
        touch-action: none;
    }
}

.navigation-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    z-index: 50;

    .navigation {
        color: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        background-color: $dark-gray;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
        height: $navigation-height;

        &__item-container {
            display: flex;
            align-items: center;
            height: $navigation-height;

            &--left {
                padding-left: 25px;
            }

            &--right {
                padding-right: 25px;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            height: $navigation-height;
            padding: 0 1rem;
            color: $white;
            cursor: pointer;
            user-select: none;

            &--with-margin {
                margin: 0 15px;
            }

            &:focus {
                outline: none;
                box-shadow: inset 0 0 0 2px $edsights-blue;
            }

            &:hover,
            &.active {
                background-color: $light-gray-2;
            }

            &--badge {
                border-radius: 10rem;
                margin-left: 0.6em;
                justify-content: center;
                width: 22px;
                height: 22px;
            }

            &--badge-count {
                color: #fff;
                background-color: #e50000;
            }

            &--unselected {
                &:hover {
                    color: unset;
                    background-color: unset;
                }

                &.active {
                    color: unset;
                    background-color: unset;
                }

                &:active {
                    color: unset;
                }
            }

            &__logout-wrapper {
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
                padding-left: 0;
                padding-bottom: 50px;
            }

            &__logout {
                display: flex;
                justify-content: center;
                width: 100%;
                padding-left: 0;
            }

            &__icon {
                fill: white;
                text-align: center;
            }
        }
    }
}

.user {
    display: flex;
    align-items: center;
    font-size: 12px;
    background: none;
    border: 0;
    cursor: pointer;

    &:focus {
        outline: 2px solid $edsights-blue;
    }

    &__avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        background: #f3f5f8;
        color: $dark-gray;
        border-radius: 50%;
        font-weight: bold;
    }

    &__initials {
        font-size: 1.2rem;
    }
}

.help-link,
.sitemap-link {
    margin-left: 1rem;
    display: flex;
}

.current-school {
    display: flex;
    width: 100px;
}

.vertical-line {
    width: 1px;
    height: 35px;
    background: #54565f;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    align-content: center;
    align-items: center;
}

.dropdown-container {
    color: black;
}

.search-box {
    display: block;
}

.icon-resources,
.icon-sitemap {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
        color: #fff;
        font-size: 12px;
        margin-left: 0.5rem;
    }

    img {
        // required for safari/webkit browsers
        max-height: 25px;
        width: 100%;
    }
}

.accessible-resources {
    width: 102px;
}

.nav__flex {
    display: flex;
}
</style>
